import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './SideNav.styles'

const SideNav = ({ navItems, navTitle }) => (
  <Styled.Nav>
    <Styled.Typography variant="feature">{navTitle}</Styled.Typography>
    {navItems.map(({ linkText, destinationUrl }) => (
      <Styled.Link key={linkText} to={destinationUrl}>
        {linkText}
      </Styled.Link>
    ))}
  </Styled.Nav>
)

SideNav.propTypes = {
  navTitle: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string.isRequired,
      destinationUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SideNav
