/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { documentToReactComponents as mapRichText } from '@contentful/rich-text-react-renderer'
import _get from 'lodash.get'

import Typography from '../components/Typography'
import Section from '../components/Section'
import Hero from '../components/Hero'
import richTextBlockMap from '../utils/richTextBlockMap'
import Layout from '../layouts/index'
import SideNav from '../components/SideNav'

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 25% 75%;
  }
`

const LandingWithSidebar = ({ data }) => {
  const {
    type,
    seoTitle,
    seoDescription,
    heading,
    mainContent,
  } = data.contentfulPageWithSidebar

  const edges = _get(data, 'allContentfulPageWithSidebar.edges')
  const navItems = edges
    ? edges
      .filter(({ node }) => type === node.type)
      .map(({ node }) => ({
        linkText: node.shortName,
        destinationUrl: node.path,
      }))
    : []

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>
      <Layout>
        <Hero
          image={{
            childImageSharp: data.file.childImageSharp,
            description: 'Green Gradient Background',
          }}
          darkText={false}
        >
          <Typography variant="h1">{heading}</Typography>
        </Hero>
        <main>
          <Section disableHeader>
            <StyledGrid>
              <SideNav navTitle={type} navItems={navItems} />
              <div>
                {mainContent && mapRichText(mainContent.json, richTextBlockMap)}
              </div>
            </StyledGrid>
          </Section>
        </main>
      </Layout>
    </>
  )
}

LandingWithSidebar.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default LandingWithSidebar

export const query = graphql`
  query LandingPageWithSidebarQueryJE($id: String!) {
    file(name: { eq: "Hero-Green-Energy" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulPageWithSidebar(sort: { fields: shortName }) {
      edges {
        node {
          path
          type
          shortName
        }
      }
    }
    contentfulPageWithSidebar(id: { eq: $id }) {
      type
      seoTitle
      seoDescription
      heading
      promoCode
      mainContent {
        json
      }
    }
  }
`
