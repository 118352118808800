import styled from '@emotion/styled'
import CoreLink from '../Link'
import CoreTypography from '../Typography'

export const Nav = styled.nav`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`

export const Typography = styled(CoreTypography)`
  color: ${({ theme }) => theme.colors.secondary.darker.hex};
`

export const Link = styled(CoreLink)`
  border-left: 1px solid ${({ theme }) => theme.colors.light.darkest.hex};
  padding-top: ${({ theme }) => theme.spacing.xxs};
  padding-right: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.xxs};
  padding-left: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.secondary.base.hex};
  display: block;
  text-decoration: none;
`
